import "./App.css";

function App() {
  return (
    <div className="App">
      <img className="hero-image" alt="Hem hero" src="/hem-hero.svg" />
      <section>
        <h1>Welcome to Hem Communities</h1>
        <h2>
          We are passionate about creating vibrant and growing communities. We
          partner with developers, councils, and our community to do so. We
          believe everybody deserves a place to call home.
        </h2>
        <p>
          Hem Communities is a not-for-profit company focused on delivering
          affordable housing. Our primary goal is to create affordable housing
          that is local, sustainable, and contemporary. We hope families,
          singles or couples who live in our properties can stay and become part
          of their local community.
        </p>
        <h3>Why does HEM Communities exist?</h3>
        <p>
          The last 10 years have drawn our attention to the great need for
          affordable housing everywhere and specifically in South West Victoria.
          It’s heartbreaking to learn of families who cannot live in the
          communities they call home due to a shortage of housing. We believe
          the responsibility to provide housing cannot reside with government
          alone.
        </p>
        <h3>What is happening now?</h3>
        <p>
          Hem Communities Ltd was founded in 2021, with the intent of commencing
          projects in 2023. Watch this space as Hem Communities comes to life in
          the coming months.
        </p>
        <p>
          We’re thankful you are curious to see what we do. We hope you’ll also
          be part of the changing story for our community.
        </p>
        <div className="timeline">
          <img
            className="desktop"
            alt="Hem Timeline"
            src="/timeline-desktop.svg"
          />
        </div>
        <h3>How to contact us</h3>
        <p>For any further enquiries please contact us at <a href="mailto:admin@hem.org.au">admin@hem.org.au</a></p>
      </section>
    </div>
  );
}

export default App;
